import * as React from "react";
import SanityImage from "gatsby-plugin-sanity-image";

export const LogoCloud = ({ items, useLargeLogos = false }) => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="mt-6 lg:mt-8 flex flex-row flex-wrap justify-center items-center -mx-4">
        {items &&
          items.map(item => {
            if (!item.asset) return null;
            const imgElement = (
              <SanityImage
                {...item}
                className="h-full w-full object-contain"
                width={150}
              />
            );
            const imgClassName = `h-full overflow-hidden
              ${
                useLargeLogos
                  ? "sm:w-40 w-24 sm:max-h-36 max-h-20"
                  : "sm:w-36 w-24 sm:max-h-28 max-h-20"
              }`;
            return (
              <div
                className="flex justify-center items-center lg:w-40 sm:w-36 w-24 sm:h-28 h-20 lg:mx-10 mx-4 my-4"
                key={item.asset._id}
              >
                {item.url ? (
                  <a
                    href={item.url}
                    className={imgClassName}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {imgElement}
                  </a>
                ) : (
                  <div className={imgClassName}>{imgElement}</div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};
