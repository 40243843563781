import React from "react";
import { InboxIcon, TrashIcon, UsersIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { PortableText } from "@portabletext/react";
import { SectionContainer } from "../layout";
import { PortableTextComponents } from "../../components/PortableTextComponents";

export const BulletFeatureSection = ({
  featuresSection,
  containerClassName,
}) => {
  const { title, features, description, _rawIntroduction } = featuresSection;
  return (
    <SectionContainer
      title={title}
      description={description}
      className={`max-w-7xl mx-auto ${containerClassName || ""}`}
    >
      <div className="mx-auto max-w-xl lg:max-w-none">
        <div className="prose">
          <PortableText
            value={_rawIntroduction}
            components={PortableTextComponents}
          />
        </div>
        <ul className="mt-6">
          {features.map(feature => (
            <li className="text-gray-900 relative pl-9" key={feature.title}>
              <CheckCircleIcon
                className="absolute left-1 top-1 h-5 w-5 text-action-600"
                aria-hidden="true"
              />
              {feature.title}
            </li>
          ))}
        </ul>
      </div>
    </SectionContainer>
  );
};
