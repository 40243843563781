import * as React from "react";
import { LogoCloud } from "../atoms/LogoCloud";
import { SectionContainer } from "../layout";
import { lightBackgroundColor } from "../../constants";

export const References = ({
  header,
  references,
  useLargeLogos = false,
  backgroundColor = lightBackgroundColor,
}) => {
  return (
    <SectionContainer title={header} className={backgroundColor}>
      <LogoCloud items={references.logos} useLargeLogos={useLargeLogos} />
    </SectionContainer>
  );
};
