import React, { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";

const SliderProgress = props => {
  const { nSlides, index, setShowItem } = props;

  if (nSlides < 2) return null;

  const dots = [];

  for (let i = 0; i < nSlides; i++) {
    dots.push(
      <div
        key={i}
        className={`w-3 h-3 rounded-full border mx-1 border-action cursor-pointer ${
          index === i ? "bg-action" : ""
        }`}
        onClick={() => setShowItem(i)}
      />,
    );
  }

  return <div className={"flex flex-1 justify-center -mx-1"}>{dots}</div>;
};

export const Carousel = ({ elements }) => {
  const [showItem, setShowItem] = useState(0);

  const itemsLength = elements.length;

  useEffect(() => {
    /*  const timer = setInterval(() => {
      setShowItem((showItem + 1) % itemsLength);
    }, 10000);
    return () => clearInterval(timer); */
  }, [showItem]);

  return (
    <>
      <div className="bg-action-600 pb-0 z-10 relative my-8 h-full ">
        {elements.map((el, index) => (
          <Transition
            key={index}
            show={Math.abs(showItem) === index}
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="absolute inset-0 flex max-w-full z-10">{el}</div>
          </Transition>
        ))}
        <div className="invisible pointer-events-none lg:h-96 h-112" />
      </div>
      <div className="absolute mx-auto inset-x-0 bottom-0">
        <SliderProgress
          nSlides={itemsLength}
          index={Math.abs(showItem)}
          setShowItem={setShowItem}
        />
      </div>
    </>
  );
};
