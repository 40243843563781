import * as React from "react";
import { SectionContainer } from "../layout";
import { Link } from "../atoms/Link";
import { darkBackgroundColor } from "../../constants";

export const Faq = ({
  title,
  faqs,
  moreButton,
  backgroundColor = darkBackgroundColor,
}) => {
  return (
    <SectionContainer className={backgroundColor} title={title}>
      <div className="max-w-7xl mx-auto">
        <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
          {faqs?.map((faq, index) => (
            <div key={index}>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {faq.question}
              </h3>
              <p className="mt-2 text-base text-gray-500">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
      {moreButton?.text && moreButton?.href && (
        <div className="mt-12 flex justify-center">
          <div className="ml-3 inline-flex">
            <Link
              to={moreButton.href}
              className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-action-600 hover:bg-action-700"
            >
              {moreButton.text}
            </Link>
          </div>
        </div>
      )}
    </SectionContainer>
  );
};
