import React from "react";
import { LANG_DE_DE, LANG_FR_BE, lightBackgroundColor } from "../../constants";

const LANGUAGE_TO_FORM = {
  [LANG_FR_BE]: {
    endpoint: "https://getform.io/f/bqonlnzb",
    translations: {
      title: "Demander une démonstration personnalisée",
      formDescription:
        "Notre équipe est à votre disposition pour vous présenter la solution et discuter de sa mise en place dans votre contexte.",
      firstName: "Prénom",
      lastName: "Nom",
      email: "Email",
      phone: "Numéro de téléphone",
      organisation: "Organisation",
      role: "Fonction",
      description: "Projet",
      submitText: "Obtenir plus d’informations",
    },
  },
  [LANG_DE_DE]: {
    endpoint: "https://getform.io/f/agdymyob",
    translations: {
      title: "Eine persönliche Beratung anfragen",
      formDescription:
        "Unser Team steht Ihnen gerne zur Verfügung, um Ihnen Omnidoc vorzustellen und die Umsetzung in Ihrem Kontext zu besprechen.",
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail",
      phone: "Telefonnummer",
      organisation: "Organisation",
      role: "Funktion",
      description: "Projekt",
      submitText: "Informationen erhalten",
    },
  },
};

/**
 * ContactForm component for the dynamic pages. It takes a language as input and render a form with the correct linked getForms.
 * For the Component in French, see static component ContactForm.jsx
 */
export const DynamicContactForm = ({
  language,
  backgroundColor = lightBackgroundColor,
}) => {
  const { endpoint, translations } = LANGUAGE_TO_FORM[language] || {};

  if (!endpoint || !translations) {
    return null;
  }

  return (
    <div
      className={`${backgroundColor} relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8`}
    >
      <div className="relative max-w-3xl mx-auto">
        <div className="text-center mb-8">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            {translations.title}
          </h2>
        </div>

        <form
          className="space-y-8 divide-y divide-gray-200"
          method="post"
          action={endpoint}
        >
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="pt-8">
              <div className="mb-12 text-base text-gray-500">
                <p>{translations.formDescription}</p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {translations.firstName}&nbsp;*
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {translations.lastName}&nbsp;*
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {translations.email}&nbsp;*
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {translations.phone}
                  </label>
                  <div className="mt-1">
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      autoComplete="tel"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="organisation"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {translations.organisation}
                  </label>
                  <div className="mt-1">
                    <input
                      id="organisation"
                      name="organisation"
                      type="text"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {translations.role}
                  </label>
                  <div className="mt-1">
                    <input
                      id="role"
                      name="role"
                      type="text"
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {translations.description}
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      rows={3}
                      className="shadow-sm focus:ring-action-500 focus:border-action-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      defaultValue={""}
                    />
                  </div>
                </div>

                {/* Honeypot input for bots spam */}
                <input
                  type="hidden"
                  name="_gotcha"
                  style={{ display: "none !important" }}
                />
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-action-600 hover:bg-action-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-action-500"
              >
                {translations.submitText}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
