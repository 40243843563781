import * as React from "react";
import { LogoCloud } from "../atoms/LogoCloud";
import { SectionContainer } from "../layout";
import { lightBackgroundColor } from "../../constants";
import { classNames } from "../../utils";

export const Partners = ({
  title,
  description,
  logoCloudTitle,
  partners,
  useLargeLogos = false,
  containerClassName = null,
  backgroundColor = lightBackgroundColor,
}) => {
  return (
    <SectionContainer
      title={title}
      description={description}
      className={classNames(containerClassName, backgroundColor)}
    >
      {logoCloudTitle ? (
        <h2 className="mt-3 sm:mt-4 text-xl flex justify-center">
          {logoCloudTitle}
        </h2>
      ) : null}
      <LogoCloud items={partners.logos} useLargeLogos={useLargeLogos} />
    </SectionContainer>
  );
};
