import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { SectionContainer } from "../layout";
import { PortableText } from "@portabletext/react";
import { PortableTextComponents } from "../../components/PortableTextComponents";

export const InlineFeatureSection = ({
  featuresSection,
  containerClassName,
}) => {
  const { title, features, description, _rawIntroduction } = featuresSection;

  return (
    <SectionContainer
      title={title}
      description={description}
      className={`max-w-7xl mx-auto ${containerClassName || ""}`}
    >
      <div className="mx-auto max-w-xl lg:max-w-none prose">
        <PortableText
          value={_rawIntroduction}
          components={PortableTextComponents}
        />
      </div>
      <div className="mx-auto mt-16 max-w-xl sm:mt-12 lg:mt-12 lg:max-w-none">
        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
          {features.map(feature => (
            <div key={feature.name} className="flex flex-col">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="mb-2 flex h-12 w-12 items-center justify-center">
                  <SanityImage
                    {...feature.image}
                    height={800}
                    aria-hidden="true"
                  />
                </div>
                {feature.title}
              </dt>
              <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                <p className="flex-auto">
                  <dd className="inline">
                    <PortableText
                      value={feature._rawDescription}
                      components={PortableTextComponents}
                    />
                  </dd>
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </SectionContainer>
  );
};
