import React from "react";
import { SectionContainer } from "../layout";
import { CheckCircleIcon, InformationCircleIcon } from "@heroicons/react/solid";
import { PortableText } from "@portabletext/react";
import { PortableTextComponents } from "../../components/PortableTextComponents";

export const ContentSection = ({ content }) => {
  const { title, description, _rawBody } = content;
  return (
    <SectionContainer title={title} description={description}>
      <div className="markdown max-w-prose mx-auto mt-16">
        <PortableText value={_rawBody} components={PortableTextComponents} />
      </div>
    </SectionContainer>
  );
};
