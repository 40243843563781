import * as React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { SectionContainer } from "../layout";

export const KeyPoints = ({ title, description, keyPoints, className }) => {
  if (keyPoints && keyPoints.length === 3) {
    return (
      <SectionContainer
        title={title}
        description={description}
        className={`${className} || bg-gray-50`}
      >
        <div className="relative max-w-7xl mx-auto">
          <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {keyPoints.map(keyPoint => (
              <div
                key={keyPoint.title}
                className="flex flex-col rounded-lg shadow-lg overflow-hidden p-6 bg-white"
              >
                <div className="flex-shrink-0 flex mx-auto my-auto items-center justify-center h-22 w-22 rounded-md bg-white text-action-500 sm:h-24 sm:w-24">
                  {keyPoint.icon && keyPoint.icon.asset && (
                    <SanityImage
                      {...keyPoint.icon}
                      className="h-14 w-14"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-xl font-semibold text-gray-900">
                      {keyPoint.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {keyPoint.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </SectionContainer>
    );
  }
  return null;
};
