import * as React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { Carousel } from "../atoms/Carousel";
import { SectionContainer } from "../layout";
import { lightBackgroundColor } from "../../constants";

const testimonialElement = ({ quote, authorName, authorTitle, image }) => {
  return (
    <div className="mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
      {image && (
        <div className="relative -my-8">
          <div className="mx-auto max-w-xs  sm:max-w-md px-4 sm:px-6 lg:p-0 lg:h-full">
            <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
              <SanityImage
                className="object-cover lg:h-full lg:w-full"
                width={400}
                {...image}
              />
            </div>
          </div>
        </div>
      )}
      <div className="lg:m-0 lg:col-span-2 lg:pl-8 block">
        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:max-w-none flex items-center h-full">
          <blockquote>
            <div>
              <svg
                className="h-12 w-12 text-white lg:opacity-25 opacity-75"
                fill="currentColor"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="sm:mt-6 mt-2 text-base md:text-xl lg:text-2xl font-medium text-white">
                {quote}
              </p>
            </div>
            <footer className="sm:mt-6 mt-2">
              <p className="text-base font-medium text-white">{authorName}</p>
              <p className="text-base font-medium text-action-200">
                {authorTitle}
              </p>
            </footer>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export const Testimonials = ({
  testimonials,
  backgroundColor = lightBackgroundColor,
}) => {
  return (
    <SectionContainer fullWidth className={backgroundColor}>
      <div className="overflow-x-hidden relative">
        <Carousel
          elements={testimonials.map(t =>
            testimonialElement({
              quote: t.quote,
              authorName: t.authorName,
              authorTitle: t.authorTitle,
              image: t.image,
            }),
          )}
        />
      </div>
    </SectionContainer>
  );
};
