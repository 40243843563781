import React, { useState } from "react";
import { Link } from "gatsby";
import { Card } from "../atoms/Card";
import { SectionContainer } from "../layout";

export const InteropRoles = ({ tabs }) => {
  return (
    <SectionContainer className="bg-gray-50">
      <div className="relative max-w-7xl mx-auto">
        <div className="max-w-lg mx-auto flex lg:max-w-none space-y-5 lg:space-y-0 lg:space-x-5 justify-center lg:flex-row flex-col">
          {tabs.map(({ title, description, href, image }) => (
            <div className="lg:max-w-sm lg:min-h-full lg:flex">
              <Card
                title={title}
                href={href}
                description={description}
                image={image}
              />
            </div>
          ))}
        </div>
      </div>
    </SectionContainer>
  );
};
