import React from "react";

import illustration from "../../images/demander_une_demo.png";
import { Link } from "gatsby";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import {
  LANG_DE_DE,
  LANG_FR_BE,
  LANG_FR_FR,
  LANGUAGE_TO_HOMEPAGE,
  lightBackgroundColor,
} from "../../constants";

const LANGUAGE_TO_FORM_THANKS = {
  [LANG_FR_FR]: {
    title: "C'est noté !",
    formSubmitConfirm: "Nous avons bien reçu votre demande de démo.",
    backToHome: "Retourner à l'accueil",
  },
  [LANG_FR_BE]: {
    title: "C'est noté !",
    formSubmitConfirm: "Nous avons bien reçu votre demande de démo.",
    backToHome: "Retourner à l'accueil",
  },
  [LANG_DE_DE]: {
    title: "Vielen Dank!",
    formSubmitConfirm:
      "Wir haben Ihre Anfrage erhalten und melden uns in kürze.",
    backToHome: "Zurück zur Homepage",
  },
};

/**
 * Thanks component after a ContactForm has been filled. It is meant to be placed on its own page.
 */
export const DynamicContactFormThanks = ({
  language,
  backgroundColor = lightBackgroundColor,
}) => {
  const translations = LANGUAGE_TO_FORM_THANKS[language];

  if (!translations) {
    return null;
  }

  const homeUrl = LANGUAGE_TO_HOMEPAGE[language] || "../";

  return (
    <div
      className={`${backgroundColor} flex flex-col w-full items-center pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8`}
    >
      <h1 className="text-3xl tracking-tight font-extrabold sm:text-4xl text-action-600">
        {translations.title}
      </h1>
      <p className="mt-8 text-lg text-gray-500 text-center">
        {translations.formSubmitConfirm}
      </p>
      <div className="w-64 sm:w-80 h-auto flex justify-center mt-10">
        <img src={illustration} alt="" />
      </div>
      <Link
        to={homeUrl}
        className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-action-700 bg-action-100 hover:bg-action-200 mt-10"
      >
        <span className="mr-1">
          <ArrowLeftIcon className="w-5" strokeWidth="0.5" />
        </span>
        {translations.backToHome}
      </Link>
    </div>
  );
};
