import * as React from "react";
import { SectionContainer } from "../layout";
import { Link } from "../atoms/Link";

export const Cta = ({
  titleLine1,
  titleLine2,
  description,
  buttonText,
  url,
}) => {
  return (
    <div className="bg-action-600 text-white">
      <SectionContainer
        title={<span className="text-white">{titleLine1}</span>}
        description={<span className="text-action-200">{description}</span>}
      >
        <div className="text-center">
          <Link
            to={url}
            className="inline-block px-5 py-3 border border-transparent text-base font-medium rounded-md text-action-600 bg-white hover:bg-gray-50 mx-auto"
          >
            {buttonText}
          </Link>
        </div>
      </SectionContainer>
    </div>
  );
};
