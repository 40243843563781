import * as React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { Link } from "../atoms/Link";
import { lightBackgroundColor } from "../../constants";

export const Hero = ({
  title,
  subtitle,
  accentTitle,
  description,
  buttons,
  image,
  backgroundColor = lightBackgroundColor,
}) => {
  return (
    <div className={backgroundColor}>
      {/* Left and rights dots */}
      <div
        className="invisible z-[-1] overflow-hidden absolute inset-y-0 h-full w-full 2xl:visible"
        aria-hidden="true"
      >
        <div className="relative h-full">
          <svg
            className="absolute right-full transform translate-y-1/4 translate-x-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
            />
          </svg>
        </div>
      </div>

      {/* Content */}
      <div className="py-16 sm:mx-auto max-w-screen-2xl">
        <div className="sm:mx-auto lg:overflow-hidden">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:pl-16 lg:pr-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
              <div>
                <div className="text-4xl font-black tracking-tight sm:text-5xl">
                  {subtitle && (
                    <h1 className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base mb-1">
                      {subtitle}
                    </h1>
                  )}
                  <p>
                    <span className="block text-gray-900">{title}</span>{" "}
                    <span className="block text-action-600">{accentTitle}</span>
                  </p>
                </div>
                <h2 className="mt-8 text-xl text-gray-500">{description}</h2>
                <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                  {buttons && buttons.length > 0 && (
                    <div className="rounded-md shadow">
                      <Link
                        to={buttons[0].href}
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-action-600 hover:bg-action-700 md:py-4 md:text-lg md:px-10"
                      >
                        {buttons[0].text}
                      </Link>
                    </div>
                  )}
                  {buttons && buttons.length > 1 && (
                    <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                      <Link
                        to={buttons[1].href}
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-action-600 bg-action-100 hover:bg-action-200 md:py-4 md:text-lg md:px-10"
                      >
                        {buttons[1].text}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="sm:mx-auto relative w-full z-0 my-8 lg:mb-0 lg:mt-0 lg:h-120 sm:h-56 h-40 px-8 lg:px-0">
              <SanityImage
                {...image}
                width={800}
                className="h-full w-full object-contain xl:-ml-12"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
