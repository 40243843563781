import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { Link } from "gatsby";

export const Card = ({ title, href, description, image, icon }) => {
  return (
    <div
      key={title}
      className="flex flex-col rounded-lg shadow-lg overflow-hidden px-6 py-6 bg-white"
    >
      <div className="flex-shrink-0 flex mx-auto my-auto items-center justify-center rounded-md bg-white text-action-500">
        {image ? (
          <SanityImage {...image} height={800} className="h-40 w-auto" />
        ) : icon ? (
          <img
            className="h-14 w-14"
            aria-hidden="true"
            src={icon}
            alt={title}
          />
        ) : null}
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between text-center">
        <div>
          <p className="text-xl font-semibold text-gray-900">{title}</p>
          <p className="mt-3 text-base text-gray-500">{description}</p>
        </div>
        <div className="mt-8 flex justify-center">
          <div className="ml-3 inline-flex">
            <Link
              to={href}
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-action-700 bg-action-100 hover:bg-action-200"
            >
              En savoir plus
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
