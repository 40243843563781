import React from "react";
import { PortableText } from "@portabletext/react";

export const FeatureContainer = ({ variant, title, description, children }) => {
  const text = (
    <div className="max-w-xl lg:max-w-none mx-auto w-full">
      <div>
        <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight my-3 sm:text-3xl">
          {title}
        </h3>
        <div className="text-lg text-gray-500">
          <PortableText
            value={description}
            components={{
              block: {
                normal: ({ children }) => <p className="my-4">{children}</p>,
              },
            }}
          />
        </div>
      </div>
    </div>
  );

  const content = (
    <div>
      <div className="mt-10 relative lg:mt-0 z-0" aria-hidden="true">
        <div className="z-10">{children}</div>
      </div>
    </div>
  );

  return (
    <div className="relative z-0">
      <div className="relative mt-12 lg:mt-24 max-w-7xl mx-auto z-10 w-full">
        <div
          className={`flex lg:flex-row ${
            variant === "left" ? "flex-col" : "flex-col-reverse"
          } lg:grid lg:grid-cols-2 lg:gap-20 lg:items-center my-16`}
        >
          {variant === "left" ? (
            <>
              {text}
              {content}
            </>
          ) : (
            <>
              {content}
              {text}
            </>
          )}
        </div>
      </div>
      <svg
        className={`invisible lg:visible absolute ${
          variant === "left"
            ? "right-0 translate-x-1/3"
            : "left-0 -translate-x-1/3"
        } top-1/2 -translate-y-1/2 transform z-0`}
        width={250}
        height={500}
        fill="none"
        viewBox="0 0 250 500"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
            x={0}
            y={0}
            width={20}
            height={20}
            patternUnits="userSpaceOnUse"
          >
            <rect
              x={0}
              y={0}
              width={4}
              height={4}
              className="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width={250}
          height={500}
          fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
        />
      </svg>
    </div>
  );
};
