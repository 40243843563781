import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { PortableText } from "@portabletext/react";
import { PortableTextComponents } from "../../components/PortableTextComponents";
import { SectionContainer } from "../layout";

export const ImageFeatureSection = ({ featuresSection }) => {
  const { title, features, image, description } = featuresSection;

  return (
    <SectionContainer>
      <div className="overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:ml-auto lg:pl-4 lg:pt-4">
              <div className="lg:max-w-lg">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {title}
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  {description}
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map(feature => {
                    return (
                      <div key={feature.title} className="relative pl-16">
                        {feature.image && feature.image.asset ? (
                          <SanityImage
                            {...feature.image}
                            height={800}
                            className="absolute left-1 h-10 w-10 text-action-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <CheckCircleIcon
                            className="absolute left-1 top-1 h-8 w-8 text-action-500"
                            aria-hidden="true"
                          />
                        )}
                        <span className="inline font-semibold text-gray-900">
                          {feature.title}
                        </span>
                        <span className="inline">
                          <PortableText
                            value={feature._rawDescription}
                            components={PortableTextComponents}
                          />
                        </span>
                      </div>
                    );
                  })}
                </dl>
              </div>
            </div>
            <div className="flex items-start lg:items-center justify-center lg:order-first">
              <SanityImage
                {...image}
                height={1442}
                aria-hidden="true"
                className="max-h-96"
              />
            </div>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};
