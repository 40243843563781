import * as React from "react";
import { lightBackgroundColor } from "../../constants";
import { getOppositeBackgroundColor } from "../../utils";

export const KeyFigures = ({
  header,
  keyFigures,
  backgroundColor: topBackgroundColor = lightBackgroundColor,
}) => {
  // KeyFigures has 2 half backgrounds, the top is the backgroundColor props and the bottom is the opposite of it so matches the next section.
  const bottomBackgroundColor = getOppositeBackgroundColor(topBackgroundColor);

  return keyFigures && keyFigures.length > 0 ? (
    <div className="relative py-6">
      <div
        className={`absolute top-0 left-0 bottom-0 right-0 h-1/2 ${topBackgroundColor}`}
      ></div>
      <div
        className={`absolute left-0 bottom-0 right-0 h-1/2 ${bottomBackgroundColor}`}
      ></div>
      <div className="relative max-w-7xl mx-auto px-4 md:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <dl className="rounded-lg bg-white shadow-lg md:grid md:grid-cols-3 ring-1 ring-black ring-opacity-5">
            {keyFigures.map((figure, index) => (
              <div
                className={`flex flex-col border-gray-100 p-6 text-center md:border-0 ${
                  index > 0 ? "border-t md:border-l" : ""
                } ${
                  index < keyFigures.length - 1 ? "border-b md:border-r" : ""
                }`}
                key={index}
              >
                <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                  {figure.label}
                </dt>
                <dd className="order-1 text-4xl lg:text-5xl items-center flex justify-center">
                  <div className="inline-block text-action-600 font-extrabold tracking-tight">
                    {figure.prefix && (
                      <span className="text-action-300">{figure.prefix} </span>
                    )}
                    {figure.number}
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  ) : null;
};
